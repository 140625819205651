import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button'];

    connect() {}

    loadMore(event) {
        event.preventDefault();

        const nextPageUrl = this.buttonTarget.getAttribute('data-href');
        if (!nextPageUrl) return;

        Turbo.visit(nextPageUrl, { action: 'replace', frame: this.buttonTarget.getAttribute('data-turbo-frame') });
    }
}