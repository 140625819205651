import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.setExternalLinkAttributes();
    }

    setExternalLinkAttributes() {
        const links = this.element.querySelectorAll("a");

        links.forEach(link => {
            const url = new URL(link.href);

            const isExternal = url.origin !== window.location.origin;
            const isAllowedDomain = 
                url.hostname === 'dev.thebeautyroom.se' || 
                url.hostname === 'thebeautyroom.se';

            if (isExternal && !isAllowedDomain) {
                link.setAttribute("rel", "nofollow");
                link.setAttribute("target", "_blank");
            }
        });
    }
}