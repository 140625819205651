import { Controller } from "@hotwired/stimulus";

function getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
}

function setCookie(variable, value, expires_seconds) {
    var d = new Date();
    d = new Date(d.getTime() + 1000 * expires_seconds);
    document.cookie = `${variable}=${value}; expires=${d.toGMTString()}; path=/`;
}

function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return `%${c.charCodeAt(0).toString(16)}`;
    });
}

export default class extends Controller {
    initialize() {
        this.handleClick = this.handleClick.bind(this); // Bind so it works after navigation
        this.isHandlingClick = false; // Flag to prevent double handling
    }

    connect() {
        this.element.addEventListener('click', this.handleClick);
    }

    disconnect() {
        this.element.removeEventListener('click', this.handleClick);
    }

    handleClick(event) {
        event.preventDefault();

        // If already handling click, do nothing
        if (this.isHandlingClick) {
            return;
        }
        
        // Mark that we're handling the click
        this.isHandlingClick = true;

        const affiliateUrl = this.element.dataset.affiliateUrl;
        const clickoutUrl = this.element.dataset.clickoutUrl;
        const clickElement = this.element.dataset.clickElement;

        let payload = {
            'uid': 'HfHnIC',
            'affiliate_url': fixedEncodeURIComponent(affiliateUrl),
            'redirect_url': fixedEncodeURIComponent(window.location.href),
            'clickout_url': clickoutUrl,
            '_ga': getCookie("_ga"),
            '_wctrck': getCookie("_wctrck"),
            "metadata" : {
                "Element" : clickElement,
            },
        };

        setCookie("previousUrl", window.location.href, 86400);

        this.redirectToAffiliateURL(payload, 3000)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Invalid request');
                }
                return response.json();
            })
            .then((data) => {
                if (data.affiliate_url) {
                    const newTab = window.open(decodeURIComponent(data.affiliate_url), '_blank');
                    if (newTab) {
                        newTab.focus();
                    } else {
                        console.error('Popup blocked, can not open link.');
                    }
                } else {
                    throw new Error('Could not find affiliate URL in response');
                }
            })
            .catch(() => {
                const fallbackTab = window.open(affiliateUrl, '_blank');
                if (fallbackTab) {
                    fallbackTab.focus();
                } else {
                    console.error('Popup blocked, can not open link.');
                }
            })
            .finally(() => {
                // Reset flag after request finishes
                this.isHandlingClick = false;
            });
    }

    redirectToAffiliateURL(payload, timeoutMs) {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => reject(new Error('Request timed out')), timeoutMs);

            fetch('https://wct-1.com/api/public/v1/clickout', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
                .then(response => resolve(response))
                .catch(err => reject(err))
                .finally(() => clearTimeout(timer));
        });
    }
}