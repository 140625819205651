import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.generateFaqSchema();
    }

    generateFaqSchema() {
        // Hitta H2 med texten "Vanliga frågor"
        const h2Element = this.element.querySelector('h2');
        if (h2Element && h2Element.textContent.includes("Vanliga frågor")) {
            let questionsAndAnswers = [];
            let h3Elements = [];
            let sibling = h2Element.nextElementSibling;

            // Samla alla H3-element och deras svar under "Vanliga frågor"
            while (sibling) {
                if (sibling.tagName === 'H2') {
                    // Stoppa när nästa H2 hittas
                    break;
                } else if (sibling.tagName === 'H3') {
                    h3Elements.push(sibling);
                }
                sibling = sibling.nextElementSibling;
            }

            h3Elements.forEach(h3 => {
                let question = h3.textContent.trim();
                let answer = '';

                // Samla all text under H3 tills nästa H3 eller slutet av listan
                let sibling = h3.nextElementSibling;
                while (sibling && sibling.tagName !== 'H3') {
                    if (sibling.nodeType === Node.ELEMENT_NODE) {
                        answer += sibling.textContent.trim() + ' ';
                    }
                    sibling = sibling.nextElementSibling;
                }

                questionsAndAnswers.push({ question, answer: answer.trim() });
            });

            if (questionsAndAnswers.length > 0) {
                const schemaMarkup = {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": questionsAndAnswers.map(qa => ({
                        "@type": "Question",
                        "name": qa.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": qa.answer
                        }
                    }))
                };

                const script = document.createElement('script');
                script.type = 'application/ld+json';
                script.textContent = JSON.stringify(schemaMarkup, null, 2);

                const existingScript = document.querySelector('script[type="application/ld+json"]');
                if (existingScript) {
                    existingScript.remove();
                }

                document.head.appendChild(script);
            }
        }
    }
}