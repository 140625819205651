import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.setAltText();
    }

    setAltText() {
        const images = this.element.querySelectorAll("img");

        if (images.length > 0) {
            images[0].setAttribute("alt", `Bästa ${this.breadcrumb}`);
        }

        if (images.length > 1) {
            images[1].setAttribute("alt", `${this.breadcrumb} test`);
        }
    }

    get breadcrumb() {
        return this.element.dataset.breadcrumb;
    }
}